.desde {
    text-align: center;
    width: fit-content;
    padding-right: 0.7rem;
}

.hasta {
    text-align: center;
    width: fit-content;
    padding-right: 0.7rem;
}

.club {
    text-align: left;
    width: 100%;
}

.relacion {
    text-align: left;
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
