.popup {
    position: absolute;
    top: 25%;
    left: 35%;
    background: #f0f0ff;
    color: #333;
    border-color: #333;
    border-width: 0.1rem;
    border-style: solid;
    padding:1rem;
}

.contenedor {
    display: flex;
    flex-direction: column;
}

.texto {
    font-weight: 500;
}

.botonera {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
}

.botonera button {
    width: 4.5rem;
    height: 1.7rem;
    margin: 0.2rem;
    font-size: 0.8rem;
}

.botoneraEditar {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
}

.botoneraEliminar {
    align-self: flex-start;
}
