.nombre {
    text-align: left;
    width: 60%;
}

.desde {
    text-align: center;
    width: 19%;
}

.hasta {
    text-align: center;
    width: 19%;
}
