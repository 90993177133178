.nombre {
    text-align: left;
    width: 64%;
}

.desde {
    text-align: center;
    width: 17%;
}

.hasta {
    text-align: center;
    width: 17%;
}
