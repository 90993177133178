.baseFiltros {
    margin-left: 2rem;
}

.titulo {
    margin-top: 1rem;
    margin-bottom: 0.2rem;
}

.campoApellido {
    width: 15rem;
}

.campoNombre {
    width: 15rem;
}

.campoPais {
    width: 15rem;
}

.mensaje {
    width: 16rem;
}
