.botonVerificar {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 2rem;
}

.botonConfirmar {
    margin-top: 1rem;
    height: 2rem;
}
