.tabla_plantel {
    margin: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    align-self: center;
    text-align: left;
    width: 50%;
    border-style: solid;
    border-color: gray;
    border-collapse: collapse;
    background-color: cornsilk;
}

.tabla_plantel th {
    text-align: center;
    font-weight: bold;
    border-bottom: 0.2px solid #ddd;
    border-right: 0.5px solid #aaa;
    background-color: #bfdbc4;
    height: 30px;
}

.tabla_plantel th.titulo {
    height: 20px;
}

.tabla_plantel tr:nth-child(even) {background-color: #f2f2f2;}

.tabla_plantel td {
    border-bottom: 0.2px solid #ddd;
    border-right: 0.5px solid #aaa;
    padding: 4px;
}

.tabla_plantel td.persona {
    width: 50%;
}

.tabla_plantel td.nacimiento {
    width: 20%;
    text-align: center;
}

.tabla_plantel td.puesto {
    width: 30%;
}