.baseClubes {
    display: flex;
    flex-direction: column;
}

.cajaFiltros {
    display: flex;
    flex-direction: row;
}

.tituloFiltros {
    align-self: center;
}

.filtro {
    display: flex;
    flex-direction: column;
    width: 20%;
    text-align: center;
}

.itemFiltro {
    text-align: center;
}

.campoFiltro {
    width: 97%;
    align-self: self-end;
    text-align: left;
}

.oculto {
    visibility: hidden;
}

.baseLista {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
}

.listaClubes {
    width: inherit;
}

.columnaLateral {
    width: 25rem;
}

.tablaClubes {
    width: 100%;
}

.tablaClubes td {
    padding: 0.5 rem;
}

.mensajeSinDatos {
    height: 3rem;
    align-content: center;
 }