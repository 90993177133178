.tablaDatos {
    margin-top: 0.5rem;
    width: 98%;
}

.tablaDatos tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaDatos td.titulo {
    width: 30%;
    text-align: right;
    font-weight: 500;
    padding-right: 0.5rem;
}

.tablaDatos td.tituloLow {
    width: 30%;
    text-align: right;
    font-weight: 400;
    font-size: 90%;
    padding-right: 0.5rem;
}

.tablaDatos td.filaValor {
    display: flex;
    flex-direction: row;
    font-weight: 300;
    width: 70%;
}

.tablaDatos td.valor {
    font-weight: 300;
    width: 70%;
}

.campoAnio {
    width: 3rem;
}

.comboDocumento {
    font-size: small;
    font-weight: 300;
    width: 5rem;
}

.comboPuesto {
    font-size: small;
    font-weight: 300;
    width: 15rem;
}
