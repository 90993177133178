.tiempo {
    width: 14rem;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    justify-content: space-around;
}

.tiempo select {
    width: 5rem;
    padding-right: 0.5rem;
}

.tiempo input {
    width: 3rem;
}

