.paginador {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    margin: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
}

.boton {
    width: 1.8rem;
    height: 1.5rem;
    margin: 0.1rem;
    font-size: 0.8rem;
    text-align: center;
}


.presionado {
    border-style: inset;
    background-color: lightgrey;
}

