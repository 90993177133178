.selectorDecadas {
    margin: 0.6rem;
    display: flex;
    flex-direction: row;
    align-content: center;
}

.tituloDecadas {
    margin: 0.5rem;
    width: 8%;
}

.botoneraDecadas {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-between;
    width: 92%;
}

.botonDecada {
    margin: 0.3rem;
    width: 4rem;
    height: 1.7rem;
}