.cuerpo {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.barra_lateral {
    display: flex;
    flex-direction: column;
}

.area_principal {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    background-color: antiquewhite;
    width: fit-content;
}
