.cajaFiltros {
    display: flex;
    flex-direction: row;
}

.tituloFiltros {
    align-self: center;
}

.filtro {
    display: flex;
    flex-direction: column;
    width: 20%;
    text-align: center;
}

.itemFiltro {
    text-align: center;
}

.campoFiltro {
    width: 95%;
    align-self: center;
}

.oculto {
    visibility: hidden;
}

.baseLista {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
}

.listaPersonas {
    width: 40rem;
}

.columnaLateral {
    width: 25rem;
}

.tablaPersonas {
    width: 100%;
}

.tablaPersonas td {
    padding: 0.5 rem;
}

.mensajeSinDatos {
    display: flex;
    flex-direction: row;
    height: 6rem;
    align-items: center;
    justify-content: center;
}