.contenedor {
    align-self: center;
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 65%;
}

.titulo {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 5px;
}

.listaFechas {
    align-self: center;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.selectorFecha {
    width: 40px;
    margin: 0.1rem;
    border-width: 0.1rem;
    border-radius: 2px;
    padding-inline: 0.3rem;
    background-color: beige;
}

.selectorFecha.elegido {
    background-color: rgb(216, 255, 137);
}