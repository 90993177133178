.tablaPartidos {
    margin-top: 0.5rem;
    width: 100%;
}

.tablaPartidos tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaPartidos tr.titulo {
    font-weight: 500;
    padding-left: 0.5rem;
}

.tablaPartidos td.fecha {
    width: 15%;
}

.tablaPartidos td.torneo {
    width: 30%;
}

.tablaPartidos td.rival {
    width: 30%;
}

.tablaPartidos td.condicion {
    width: 10%;
    text-align: center;
}

.tablaPartidos td.resultado {
    width: 15%;
    text-align: center;
}
