.fichaEstadio {
    display: flex;
    flex-direction: column;
    background-color: linen;
    padding: 0;
    padding-bottom: 4rem;
    min-height: 85vh;
}

.tituloEstadio {
    width: 100%;
    font-size: x-large;
    text-align: center;
    padding: 0.5rem;
}

.columnas {
    display: flex;
    flex-direction: row;
}

.columnaCuadrosIzq {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
}

.columnaCuadrosDer {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
}

.tablaDatos {
    margin-top: 0.5rem;
    width: 95%;
}

.tablaDatos tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaDatos td.titulo {
    text-align: right;
    font-weight: 500;
    padding-right: 0.5rem;
    width: 25%;
}

.tablaDatos td.tituloLow {
    text-align: right;
    font-weight: 400;
    font-size: 90%;
    padding-right: 0.5rem;
}

.tablaDatos td.valor {
    font-weight: 300;
}

.tablaClubes {
    width: 95%;
}

.tablaClubes tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaClubes tr.titulo {
    font-weight: 500;
    padding-right: 0.5rem;
}

.tablaClubes tr.valor {
    font-weight: 300;
}

.botonera {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

.botonera button {
    width: 4rem;
    height: 1.6rem;
    margin: 0.2rem;
    font-size: 0.8rem;
}
