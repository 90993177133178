.cabeceraFormacion {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tipoFormacion {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selectFormacion {
    width: 10rem;
    margin: 0.2rem;
}

.titulo {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: large;
    font-weight: 500;
}

.botonGrabar {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
}

.grabandoFormacion {
    margin-left: 1rem;
}

.tituloSeccion {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-weight: 500;
    vertical-align: bottom;
}

.botonInsertar {
    vertical-align: bottom;
    padding: 0;
    height: 20px;
}

.persona {
    display: flex;
    flex-direction: row;
    padding: 0.1rem;
}

.tablaGoles {
    display: flex;
    flex-direction: row;
    vertical-align: text-top;
    padding-top: 0.5rem;
    width: 100%;
}

.tituloGoles {
    padding-bottom: 0.2rem;
    font-weight: 500;
}

.listaGoles {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    width: 80%;
}

.filaSeparador {
    display: flex;
}

.separador {
    width: 23rem;
    height: 0.07rem;
    background-color: transparent;
}

.separador.visible {
    background-color: rgb(212, 152, 117);
}