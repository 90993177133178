.cuadro {
    background: moccasin;
    border-color: #beb791;
    border-width: 0.1rem;
    border-style: solid;
    padding: 1rem;
    margin: 1rem;
}

.contenedor {
    display: flex;
    flex-direction: column;
}

.texto {
    font-weight: 500;
}

.botonera {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}

.botonera button {
    width: 4.5rem;
    height: 1.7rem;
    margin: 0.2rem;
    font-size: 0.8rem;
}

.botoneraEditar {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
}
