.baseFiltros {
    margin-left: 2rem;
}

.tituloPais {
    margin-top: 1rem;
}

.campoPais {
    width: 15rem;
    margin-top: 0.2rem;
}

.tituloNombre {
    margin-top: 1rem;
}

.campoNombre {
    width: 15rem;
    margin-top: 0.2rem;
}

.mensaje {
    width: 16rem;
}
