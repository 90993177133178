.titulo {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    padding: 10px;
}

.listado {
    display: flex;
    flex-direction: column;
}

.etapa {
    display: flex;
    align-items: flex-start;
    width: 300px;
    margin: 0.1rem 0.5rem;
    padding: 0.4rem;
    background-color: beige;
    border-color: transparent;
}
