.detalleGol {
    display: flex;
    flex-direction: row;
    padding: 0.1rem;
}

.tiempoGol {
    width: 3.5rem;
}

.autorGol {
    width: 80%;
}