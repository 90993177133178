.tablaDatos {
    margin-top: 0.5rem;
    width: 98%;
    border-collapse: collapse;
    margin-left: 1%;
    margin-right: 1%;
}

.tablaDatos tr {
    height: 1.4rem;
    vertical-align: top;
    border: 0;
}

.tablaDatos tr.titulos {
    text-align: center;
    font-weight: 500;
    margin-right: 0.5rem;
    width: 25%;
}

.tablaDatos tr.valores {
    font-weight: 300;
    vertical-align: middle;
}

.tablaDatos tr.elegido {
    background-color: rgb(243, 219, 177);
}

.tablaNombres td {
    border: 0;
}

.tablaDatos td.editar {
    vertical-align: middle;
}

.tablaDatos td.subtitulo {
    padding: 0.4rem;
    font-size: medium;
    font-style: italic;
}

.tablaDatos td.botonera {
    width: 3.2rem;
    display: inline-block;
    text-align: right;
}