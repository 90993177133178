.tablaFechas {
    margin-top: 0.5rem;
    width: 98%;
}

.tablaFechas tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaFechas td.titulo {
    text-align: right;
    font-weight: 500;
    padding-right: 0.5rem;
    width: 20%;
}

.tablaFechas td.valor {
    align-content: left;
    font-weight: 300;
    width: 20%;
}

.campoFechaUltimoPartido {
    width: 6rem;
}

.campoAnioCierre {
    width: 3rem;
}
