.contenedor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
}

.titulo {
  margin-top: 8rem;
  font-family: fantasy;
  font-size: 5rem;
  font-weight: 800;
}

.bienvenida {
  width: 100%;
  margin-top: 1rem;
  font-size: large;
  font-weight: 400;
}

.errorServer {
  margin-top: 1rem;
  font-weight: 300;
  font-size: medium;
  color:firebrick;
}

.boton {
  font-size: large;
  width: 10rem;
  margin: 0.5rem 0.5rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.25);
  border-radius: 4px;
  padding: 0.5rem;
  background-color: beige;
  cursor: pointer;
}

.cargando {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 40rem;
  height: 14rem;
}

.error {
  position: absolute;
  left: 0.5rem;
  bottom: 3rem;
}
