.tablaTotales {
  margin-top: 2rem;
  border-color: grey;
  border-style: solid;
  padding: 1rem;
  width: 20rem;
}

.tablaTotales td.total {
  text-align: right;
  font-weight: 500;
  padding-top: 0.5rem;
}

.tablaTotales td.detalle {
  padding-top: 0.5rem;
  text-align: left;
}