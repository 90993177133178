.tablaTorneos {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 95%;
}

.tablaTorneos tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaTorneos tr.titulos {
    font-weight: 500;
}

.tablaTorneos td.torneo {
    width: 48%;
}

.tablaTorneos td.equipo {
    width: 40%;
}

.tablaTorneos td.pj {
    width: 4%;
}

.tablaTorneos td.goles {
    width: 4%;
}

.tablaTorneos td.expulsiones {
    width: 4%;
}
