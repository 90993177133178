.fichaPartido {
    display:flex;
    flex-flow: column;
    background-color: antiquewhite;
    height: 100%;
}

.botonVolver {
    background-color: transparent;
    border-style: hidden;
    cursor: pointer;
    align-self: flex-start;
    padding-bottom: 0.5rem;
}

.cabecera {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: stretch;
    width: 100%;
    padding: 0.5rem;
}

.columnaDatos {
    display: flex;
    flex-flow: column;
    width: 55%;
}

.columnaResultado {
    display: flex;
    flex-direction: column;
    width: 55%;
}

.formaciones {
    padding-top: 1rem;
    display: flex;
    flex-flow: row;
    width: 80%;
    align-self: center;
    justify-content: space-evenly;
}

.columnaFormacion {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    width: 40%;
}

.incidencias {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    display: flex;
    flex-flow: column;
    width: 80%;
    align-self: center;
    justify-content: flex-start;
}

.filaIncidencias {
    display: flex;
    flex-flow: row;
    align-content: flex-start;
    padding-top: 0.5rem;
}

.filaIncidenciasTitulo {
    width: 7rem;
    align-self: flex-start;
    text-align: left;
}

.filaIncidenciasDetalle {
    width: 90%;
    display: flex;
    flex-flow: column;
    align-content: flex-start;
    text-align: left;
}