.fila {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 1.5rem;
}

.espacio {
    width: 1.2rem;
    padding-right: 0.7rem;
}

.tecnico {
    width: 75%;
    background-color: transparent;
}

.detalles {
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 0.2rem;
}

.detalles.editar {
    justify-content: flex-start;
}

.link {
    text-decoration: none;
    color:black;
    padding: 0px;
    display: block;
}