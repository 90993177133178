.cuadroDatos {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 0.2rem;
    border-style: solid;
    border-color: #eeebcd;
    background-color: blanchedalmond;
    padding: 0rem;
    margin-bottom: 0.5rem;
    width: 98%;
}

.tituloCuadroDatos {
    padding: 0rem;
    margin-left: 0rem;
    font-family: monospace;
}
