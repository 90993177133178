.cabecera {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 30px;
    align-items: center;
    background-color: lightgreen;
}

.cabecera button {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    padding: 1rem;
    background-color: transparent;
    border-style: hidden;
}

.cabecera button.volver {
    cursor: pointer;
}