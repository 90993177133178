.item {
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.25);
    border-radius: 4px;
    padding: 0.2rem;
    background-color: beige;
    visibility: visible;
    cursor: pointer;
}

.disabled {
    background-color: lightgray;
    cursor: auto;
}

.selected {
    background-color: tan;
}