.busqueda {
    font-size: small;
}

.listaVacia {
    padding-top: 1rem;
    font-size: small;
    font-style: italic;
    font-weight: 200;
    color: darkblue;
}

.listaClubes {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding: 0.5rem;
}

.itemClub {    
    background-color: transparent;
    border-style: hidden;
    cursor: pointer;
    text-align: left;
}

.cargando {
    padding-top: 1rem;
}