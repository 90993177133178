.tabla_partidos {
    margin: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    align-self: center;
    text-align: left;
    width: 70%;
    border-style: solid;
    border-color: gray;
    border-collapse: collapse;
    background-color: cornsilk;
}

.tabla_partidos th {
    text-align: center;
    font-weight: bold;
    border-bottom: 0.2px solid #ddd;
    border-right: 0.5px solid #aaa;
    background-color: #bfdbc4;
    height: 40px;
}

.tabla_partidos tr:nth-child(even) {background-color: #f2f2f2;}

.tabla_partidos td {
    border-bottom: 0.2px solid #ddd;
    border-right: 0.5px solid #aaa;
    padding: 4px;
}

.tabla_partidos td.fecha {
    width: 10%;
    text-align: center;
}

.tabla_partidos td.condicion {
    width: 8%;
    text-align: center;
}

.tabla_partidos td.equipo {
    width: 35%;
    padding-left: 4px;
}

.tabla_partidos td.result {
    width: 12%;
    text-align: center;
}
