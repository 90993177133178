.filaDatos {
    display: flex;
    flex-direction: row;
}

.campoMes {
    width: 3rem;
}

.campoAnio {
    width: 3rem;
}

.campoFecha {
    width: 6rem;
}

.textoParcial {
    font-size: medium;
}