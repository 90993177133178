.tablaDatos {
    margin-top: 0.5rem;
    width: 95%;
}

.tablaDatos tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaDatos td.titulo {
    font-weight: 500;
    padding-right: 0.5rem;
}

.tablaDatos tr.valores {
    font-weight: 300;
}

.tablaDatos td.nombre {
    width: 40%;
}

.tablaDatos td.categorias {
    width: 60%;
}

.categoria {
    padding-right: 0.5rem;
}
