.contenedor {
    display: flex;
    flex-direction: row;
}

.columna {
    display: flex;
    flex-direction: column;
}

.tituloLista {
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.lista {
    visibility: visible;
    width: 250px;
}

.lista.ocultar {
    visibility: hidden;
    height: 0px;
}

.listaSecundaria {
    visibility: visible;
    width: 230px;
    align-self: flex-end;
}

.itemSecundario {
    font-size: small;
    align-self: flex-end;
}

.botonInsertar {
    vertical-align: middle;
}

.columnaCuadros {
    display: flex;
    flex-direction: column;
}

.cuadroTorneos {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 28rem;
    border: 0.2rem;
    border-style: solid;
    border-color: #eeebcd;
    background-color: blanchedalmond;
    padding: 1rem;
}

.botonTorneo {
    background-color: transparent;
    border-style: hidden;
    cursor: pointer;
}

.iconoTitulo {
    margin-left: 0.5rem;
    width: 17px;
    height: 17px;
    vertical-align: bottom;
}

.cuadroPartidos {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40rem;
    border: 0.2rem;
    border-style: solid;
    border-color: #eeebcd;
    background-color: blanchedalmond;
    padding: 1rem;
}

.tituloCuadroDatos {
    padding: 0.5 rem;
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: x-large;
}

.cuadroTotales {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40rem;
    height: 12rem;
    border: 0.2rem;
    border-style: solid;
    border-color: #eeebcd;
    background-color: bisque;
    padding: 1rem;
}

.tituloCuadroTotales {
    padding: 0.5 rem;
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: large;
}

.tablaDatos {
    margin-top: 1rem;
    width: 95%;
}

.tablaDatos tr {
    height: 1.5rem;
    vertical-align: top;
}

.tablaDatos td.titulo {
    width: 30%;
    text-align: right;
    font-weight: 500;
    padding-right: 0.5rem;
}

.tablaDatos td.totales {
    width: 20%;
    text-align: right;
    font-weight: 500;
    padding-right: 0.5rem;
}

.tablaDatos td.totales a {
    text-decoration: solid;
    color: black;
}

.tablaDatos td.valor {
    font-weight: 300;
    width: 70%;
}

.comboDatos {
    font-size: small;
    font-weight: 300;
    width: 70%;
}

.cargando {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.error {
    position: absolute;
    left: 0.5rem;
    bottom: 3rem;
}

.botonera {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

.botonera button {
    width: 4rem;
    height: 1.6rem;
    margin: 0.2rem;
    font-size: 0.8rem;
}