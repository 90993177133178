.ficha {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 4rem;
}

.cabecera {
    background-color: #ffffef;
}

.contenedor {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titulo {
    width: 100%;
    font-size: x-large;
    text-align: center;
    padding: 0.5rem;
}
