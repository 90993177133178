.cuadroFiltros {
    display: flex;
    justify-content: center;
    width: 100%;
}

.filtro {
    display: inline-block;
    padding: 8px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    user-select: none;
}

.filtro.selected {
    background-color: #007bff;
    color: white;
}

.filtro input[type="radio"] {
    display: none;
}
