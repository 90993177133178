.resultadoPartido {
    display: flex;
    flex-flow: column;
    width: 90%;
    align-self: center;
    justify-content: space-evenly;
}

.filaResultado {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: stretch;
    padding-bottom: 0.8rem;
}

.nombreEquipo {
    font-size: large;
    font-weight: bold;
    width: 25rem;
}

.resultadoEquipo {
    font-size: large;
    font-weight: bold;
}

.botonEditar {
    width: 25%;
    text-align: right;
}

.filaDetalle {
    display: flex;
    flex-flow: row;
    width: 60%;
    align-self: center;
    text-align: start;
}

.filaDetalleTitulo {
    width: 15%;
}

.filaDetalleValor {
    width: 85%;
    display: flex;
    flex-flow: row;
}

.linkEquipo {
    text-decoration: none;
    color:black;
    padding: 0px;
    display: block;
}