.errorContainer {
    display: flex;
    flex-direction: column;
    padding:0.5rem;
}

.errores {
    font-weight: 300;
    font-size: small;
    color:firebrick;
}
