.botonTransparente {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    padding: 0.1rem;
}

.botonArriba {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 1px;
    cursor: pointer;
}

.botonArriba:disabled {
    cursor: default;
}

.botonAbajo {
    background: #e5e5e5;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
       -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
            box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
    padding: 0px;
    cursor: pointer;
}

.botonEstadisticas {
    width: 9rem;
    height: 2.5rem;
}

.botonTexto {
    width: 8rem;
    height: 2.5rem;
}

.presionado {
    background: #e5e5e5;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
       -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
            box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
    padding: 0px;
}

button span {
    display: none;
    position: absolute;
    top: inherit;
    height: 1rem;
    width: fit-content;
    background: #fff;
    color: #333;
    border-color: #333;
    border-width: 0.1rem;
    border-style: solid;
}

button:hover span {
    display: block;
}
