.detalle {
    width: 22rem;
    display: flex;
    flex-direction: row;
    padding-top: 0.5rem;
    justify-content: space-around;
}

.detalle select {
    width: 12rem;
}
