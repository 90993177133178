.main {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.menu {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  background-color: #003600;
  position: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.menu li {
  position: relative;
}

.menu a {
  text-decoration: none;
  color: #fff;
  padding: 10px;
  display: block;
}

.linkMenuConSubmenu {
  text-decoration: none;
  color: #fff;
  padding: 0px;
  display: block;
}

.submenu {
  text-decoration: none;
  color: #fff;
  padding: 10px;
  display: block;
  cursor: pointer;
}

.submenuDisabled {
  text-decoration: none;
  color: #fff;
  padding: 10px;
  display: block;
  cursor: default;
}

.submenuContent {
  display: none;
  position: absolute;
  background-color: #005000;
  list-style-type: none;
  padding: 0;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 10rem;
}

.submenuContent li {
  text-align: left;
}

.submenuContent a {
  color: #fff;
  padding: 10px;
  display: block;
  width: 100%;
  text-align: left;
}

.menuConSubmenu:hover .submenuContent {
  display: block;
}

.submenu:hover .submenuContent {
  display: block;
}

.menuBack {
  background-color: #003600;
  margin-top: 38px;
  padding: 0;
}

.contenedor {
  padding: 0;
}

.disabledLink {
  pointer-events: none;
}
