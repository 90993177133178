.cuadro {
    width: 90%;
    align-self: center;
}

.tablaDatos {
    margin-top: 0.5rem;
    width: 95%;
}

.tablaDatos tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaDatos td.titulo {
    text-align: right;
    font-weight: 500;
    padding-right: 0.5rem;
    width: 25%;
}

.tablaDatos td.tituloLow {
    text-align: right;
    font-weight: 400;
    font-size: 90%;
    padding-right: 0.5rem;
}

.tablaDatos td.valor {
    font-weight: 300;
}
