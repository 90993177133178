.tablaDatos {
    width: 100%;
}

.columnaTitulos {
    width: 15%;
    background-color: rgb(236, 236, 202);
    height: 1.5rem;
    vertical-align: middle;
}

.columnaDatos {
    width: 35%;
    font-weight: 400;
    background-color: beige;
    height: 1.5rem;
    vertical-align: middle;
    align-items: center;
}

.campoDatosEditable {
    display: flex;
    align-items: center;
}

.campoDatos {
    width: 95%;
    max-width: 95%;
}

.botonDatos {
    display: flex;
    flex-basis: 15px;
    align-items: flex-end;
}

.link {
    text-decoration: none;
    color:black;
    padding: 0px;
    display: block;
}