.fichaPersona {
    display: flex;
    flex-direction: column;
    background-color: linen;
    padding: 0;
    padding-bottom: 4rem;
}

.tituloPersona {
    width: 100%;
    font-size: x-large;
    text-align: center;
    padding: 0.5rem;
}

.columnas {
    display: flex;
    flex-direction: row;
    width: 95%;
    align-self: center;
    justify-content: space-evenly;
}

.columnaDoble {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    padding: 0 0 1rem 1rem;
}

.columnaSimple {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 0 0 1rem 1rem;
}

.cargando {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40rem;
    height: 14rem;
}

.grabando {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-style: italic;
    width: 100%;
    height: 1.5rem;
}

.error {
    padding:0.5rem;
}

.botonera {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

.botonera button {
    width: 4rem;
    height: 1.6rem;
    margin: 0.2rem;
    font-size: 0.8rem;
}

.botoneraEstadisticas {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1rem;
}
