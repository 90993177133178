.desde {
    text-align: center;
    width: fit-content;
    padding-right: 1rem;
}

.hasta {
    text-align: center;
    width: fit-content;
    padding-right: 1rem;
}

.club {
    text-align: left;
    width: 100%;
}
