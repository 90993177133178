.icono {
    margin-left: 0.5rem;
    width: 17px;
    height: 17px;
    vertical-align: bottom;
}

.tablaDatos {
    margin-top: 0.5rem;
    width: 95%;
}

.tablaDatos tr {
    height: 1.4rem;
    vertical-align: top;
}

.tablaDatos td.valor {
    font-weight: 300;
}
