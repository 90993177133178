.cuadroSolapas {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #ccc;
}

.solapa {
    padding: 7px 20px;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    cursor: pointer;
    margin-right: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0px;
    width: 8rem;
    text-align: center;
}

.solapa.selected {
    background-color: #ccc;
}

.solapa input[type="radio"] {
    display: none;
}
