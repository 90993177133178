.titulo {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
}

.listado {
    display: flex;
    flex-direction: column;
}

.division {
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin: 0.1rem 0.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.25);
    border-radius: 3px;
    padding: 0.2rem;
    background-color: beige;
}
